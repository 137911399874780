import { AppConstants } from "../_constants/App.constants";
import { IStorage } from "../_models/storage.interface";
import { ApiService } from "./api.service";
import { StorageProvider } from "./storageProvider.service";
import { UrlProviderService } from "./urlProvider.service";

export class AuthSvc extends UrlProviderService {
    // toast = useToast();
    private static instance: AuthSvc;
    private constructor() {
        super('auth-api/auth');
    }
    public static getInstance(): AuthSvc {
        if (!this.instance) {
            this.instance = new AuthSvc();
        }
        return this.instance;
    }

    private storageSvc: IStorage = StorageProvider.getStorage();
    private apiSvc: ApiService = ApiService.getInstance();

    login(data: { uid: string, credential: string }) {
        const uri = this.generateUrl('signin');
        return this.apiSvc.post(uri, data, { authenticated: false });
    }

    logout() {
        this.storageSvc.deleteAll();
        window.location.reload();
        this.storageSvc.post(AppConstants.LOCAL_STORAGE_KEY.LOGGED_OUT, 'true');

        // const navigate = useNavigate();
        // navigate(`/industries`);

        // this.toast('Logged out', 'warn');
    }

    extractAndDecodeTokenAndGetRolePath = (token: string): string | undefined => {
        this.storageSvc.post(AppConstants.TOKEN.AUTH, token);
        const tokenPayload: any = JSON.parse(atob(token.split('.')[1]));
        this.storageSvc.post(AppConstants.TOKEN.DATA, tokenPayload);
        return AppConstants.ROLES[tokenPayload.scope];
    }

    isLoggedIn = (): boolean => {
        if (!!this.storageSvc.get(AppConstants.TOKEN.AUTH)) return true;
        return false;
    }
}