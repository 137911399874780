
import { ProductType, IVendorData, ITemplateData, ICommunicationPreferences, ITripRequest, IMissingInfoPayload, IProductFeature, IVendorProfile, ISelectedProductFeatures } from "_models/vendor.interface";
import { ApiService } from "./api.service";
import { UrlProviderService } from "./urlProvider.service";
import { StorageProvider } from "./storageProvider.service";
import { VendorConstants } from "_constants/Vendor.constants";
import { IStorage } from "_models/storage.interface";
import { AppConstants } from "_constants/App.constants";
import { IAuthToken } from "_models/common.interface";
import { UtilSvc } from "./util.service";
import { GridPaginationModel } from "@mui/x-data-grid";
import { DisplayOption } from "_models/flightDboard.interface";

export class VendorMgmntSvc extends UrlProviderService {
    private static instance: VendorMgmntSvc;
    private utilSvc = UtilSvc.getInstance();
    private constructor() {
        super('vendor-mgmnt-api');
    }
    public static getInstance(): VendorMgmntSvc {
        if (!this.instance) {
            this.instance = new VendorMgmntSvc();
        }
        return this.instance;
    }

    private apiSvc: ApiService = ApiService.getInstance();
    private storageSvc: IStorage = StorageProvider.getStorage();

    vendorInfo(id?: string) {
        // console.log("vendorInfo");
        const uri = id ? this.generateUrl(`vendors/me?vid=${id}`) : this.generateUrl(`vendors/me`);
        return this.apiSvc.get(uri);
    }
    createFlightWatcherCSV(file: FormData) {
        const uri = this.generateUrl('import/watcher');
        return this.apiSvc.post(uri, file);
    }
    createTripV2(payload: ITripRequest) {
        const uri = this.generateUrl('vendors/v2/subscription');
        return this.apiSvc.post(uri, payload);
    }
    simulate(payload: any, queryParam: string | null) {
        const url = queryParam ? `trips/simulatefs?delayInMinutes=${queryParam}` : 'trips/simulatefs';
        const uri = this.generateUrl(url);
        return this.apiSvc.post(uri, payload);
    }
    searchFutureTrips(productType: ProductType, page: number = 0, size: number = 50) {
        const uri = this.generateUrl(`trips/upcoming?productType=${productType}&page=${page}&size=${size}`);
        return this.apiSvc.get(uri);
    }
    searchByCreationDate(productType: ProductType, startDate: string, endDate: string, page: number = 0, size: number = 50) {
        const uri = this.generateUrl(`trips/bycreationdate?productType=${productType}&start=${startDate}&end=${endDate}&page=${page}&size=${size}`);
        return this.apiSvc.get(uri);
    }
    searchByBookingNum(productType: ProductType, bookingnum: string, page: number = 0, size: number = 50) {
        const uri = this.generateUrl(`trips/bybookingnum?productType=${productType}&bookingno=${bookingnum}&page=${page}&size=${size}`);
        return this.apiSvc.get(uri);
    }
    searchTrip(payload: any, page: GridPaginationModel) {
        // const uri = this.generateUrl(`trips/search?searchStr=${this.utilSvc.safePayload(payload)}`);
        const uri = this.generateUrl(`trips/search?searchStr=${this.utilSvc.safePayload(payload)}&page=${page.page + 1}&size=${page.pageSize}`);
        return this.apiSvc.get(uri);
    }
    fetchUpcomingTrip(days: number, page: GridPaginationModel) {
        const uri = this.generateUrl(`travel/trips/upcoming?nextDays=${days}&page=${page.page}&size=${page.pageSize}`);
        return this.apiSvc.get(uri);
    }
    deactivateTrip(bookingnum: string, productCode: string) {
        const uri = this.generateUrl(`trips/${bookingnum}?productCode=${productCode}`);
        return this.apiSvc.delete(uri);
    }
    downloadTripSearch(payload: any) {
        const uri = this.generateUrl(`trips/search?searchStr=${this.utilSvc.safePayload(payload)}`);
        return this.apiSvc.get(uri);
    }
    getSearchFields(searchType: string) {
        const uri = this.generateUrl(`products/fields?type=${searchType}`);
        return this.apiSvc.get(uri);
    }
    createVendor(vendorData: IVendorData) {
        const uri = this.generateUrl(`vendors`);
        return this.apiSvc.post(uri, vendorData);
    }
    getVendors() {
        const uri = this.generateUrl(`vendors`);
        return this.apiSvc.get(uri);
    }
    uploadTemplate(templateData: ITemplateData, vid?: string) {
        const uri = vid ? this.generateUrl(`vendors/templates?vid=${vid}`) : this.generateUrl(`vendors/templates`);
        return this.apiSvc.put(uri, templateData);
    }
    updatePreferences(preferences: ICommunicationPreferences, id?: string) {
        const uri = id ? this.generateUrl(`vendors/preferences?vid=${id}`) : this.generateUrl(`vendors/preferences`);
        return this.apiSvc.put(uri, preferences);
    }

    getMissingPassengerInfo(tripToken: string, bookingNo: string) {
        const uri = this.generateUrl(`public/passportinfo/${tripToken}?bookingNo=${bookingNo}`);
        return this.apiSvc.get(uri);
    }
    updateMissingPassengerInfo(tripToken: string, payload: IMissingInfoPayload) {
        const uri = this.generateUrl(`public/passportinfo/${tripToken}`);
        return this.apiSvc.post(uri, payload);
    }

    getTokenData(): IAuthToken | null {
        const token: string | null = this.storageSvc.get(AppConstants.TOKEN.AUTH);
        if (token) {
            const tokenData: IAuthToken = JSON.parse(atob(token.split('.')[1]));
            return tokenData;
        }
        return null;
    }
    getRole(): string {
        let role = '';
        const tokenData = this.getTokenData();
        if (tokenData) {
            role = tokenData.scope?.toUpperCase()
        }
        return role;
    }

    getProductsFeatures() {
        const uri = this.generateUrl(`products/withfeatures`);
        return this.apiSvc.get(uri);
    }
    async fetchProductsFeaturesInfo(): Promise<any> {
        const productFeaturesLS: string = AppConstants.LOCAL_STORAGE_KEY.PRODUCTS_FEATURES_DATA;
        let productsFeaturesData: any = this.storageSvc.get(productFeaturesLS);
        if (!productsFeaturesData) {
            this.storageSvc.put(productFeaturesLS, []);
            productsFeaturesData = [];
        }
        return new Promise((resolve, reject) => {
            if (productsFeaturesData && productsFeaturesData.length) {
                resolve(productsFeaturesData)
            } else {
                this.getProductsFeatures()
                    .then((res: any) => {
                        if (res?.data) {
                            // console.log(res.data)
                            this.storageSvc.put(productFeaturesLS, res.data);
                            resolve(res.data);
                        } else {
                            resolve([]);
                        }
                    })
                    .catch((err: any) => {
                        console.error(err)
                        reject(null);
                    })
            }
        })
    }

    createTripUploaderCSV(file: FormData) {
        const uri = this.generateUrl(`import/subscriptions`);
        return this.apiSvc.post(uri, file);
    }

    vendorRouteGuard = (vendorInfo: IVendorProfile | undefined, data: string | string[], dataType: 'emailId' | 'productId'): boolean => {
        switch (dataType) {
            case 'emailId':
                const email = vendorInfo?.email;
                if (!email) return false;
                if (data.includes(email)) return true;
                return false;
            case 'productId':
                const productFeatures = vendorInfo?.productFeatures;
                if (!productFeatures) return false;
                if (!!productFeatures.find((p: IProductFeature) => p.id === data)) return true;
                return false;
            default:
                return false;
        }
    }

    vendorRouteGuard2 = (email: string | undefined, data: string | string[]): boolean => {
        // console.log(email, data);
        if (!email) return false;
        if (data.includes(email)) return true;
        return false;
    }

    fetchUpcomingTripsDisplayType(): DisplayOption | null {
        const key = AppConstants.LOCAL_STORAGE_KEY.UPCOMING_TRIPS_DISPLAY_TYPE;
        const value = this.storageSvc.get(key);
        if (value && (value === 'card' || value === 'grid')) {
            return value;
        }
        return null;
    }
    saveUpcomingTripsDisplayType(display: DisplayOption) {
        const key = AppConstants.LOCAL_STORAGE_KEY.UPCOMING_TRIPS_DISPLAY_TYPE;
        this.storageSvc.post(key, display);
    }

    fetchBillingReport(from: string, to: string, vid: string, productType: string, deactivated: boolean) {
        const uri = this.generateUrl(`trips/report?from=${from}&to=${to}&vid=${vid}&productType=${productType}&deactivated=${deactivated}`);
        return this.apiSvc.get(uri);
    }
}